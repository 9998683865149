/* eslint-disable react-hooks/exhaustive-deps */
import React, { memo, useEffect, useState } from 'react';
import { navigate } from '@reach/router';
import { useSave, RLeanState } from '@rlean/core';
import { uuid, hasValue, deepCopy, getValue } from '@rlean/utils';
import { Checkbox, Skeleton, Card, Dropdown, Menu, Drawer, Table, Button, Input, Col, Row } from 'antd';
import { FilterFilled, SearchOutlined } from '@ant-design/icons';
import MaterialIcon from '@material/react-material-icon';
import { EntityKey } from 'lib/enums/EntityKey';
import { formatDate, determineCriteriaType } from 'lib/helpers';
import { projectMetricType, statusType } from 'lib/types';
import { useActivePage, useFilters, useTableFilters } from 'lib/hooks';
import { pages, strings } from 'config';
import { Disclaimer, exportToExcel } from 'components/shared';
import ProjectMobileCards from './ProjectMobileCards';
import { getProjectTableColumns, getAllColumns } from './getProjectTableColumns';
// eslint-disable-next-line import/order
import moment from 'moment';
import { FilterIndicators } from 'components/shared/FilterIndicators';
import { TableFilterIndicators } from 'components/shared/TableFilterIndicators';

// Hooks
import { useProject } from 'lib/hooks/useProject';
import { FiltersDrawer } from './ProjectsFilterDrawer';

const filterProperties = ['selectedWorkSite', 'projectMetric', 'hiddenEmptyRowsOnColumn'];

export const ProjectsTable = memo(props => {
  const save = useSave();
  const { filters, setFilters, resetSingleFilter } = useFilters({}, filterProperties);
  const [
    businessContact,
    project,
    projectPreviousData,
    workSite,
    projectTableColumns,
    userConfiguration
  ] = RLeanState().select(({ state }) => [
    state.businessContact,
    state.project,
    state.projectPreviousData,
    state.workSite,
    state.projectTableColumns,
    state.userConfiguration
  ]);
  const [updatedProjects, setUpdatedProjects] = useState({});
  const [filtersVisible, setFiltersVisible] = useState(false);
  const [fallout, setFallout] = useState(null);
  const [pageSize, setPageSize] = useState(10);
  const savedFallout = getValue(userConfiguration, 'data', []).find(x => x.name === 'fallout');
  const savedColumns = getValue(userConfiguration, 'data', []).find(x => x.name === 'projectTableColumnVisibility');
  const statusAvailable = getValue(project, 'data', []).reduce((previousValue, currentValue) => {
    if (previousValue.includes(currentValue.projectStatus)) {
      return previousValue;
    }
    return [...previousValue, currentValue.projectStatus];
  }, []);
  const allBusinessUnits = getValue(project, 'data', []).reduce((previousValue, currentValue) => {
    if (previousValue.includes(currentValue.businessUnitName)) {
      return previousValue;
    }
    return [...previousValue, currentValue.businessUnitName];
  }, []);
  const businessUnitsAvailable = allBusinessUnits.filter(businessUnit => businessUnit !== null);
  const {
    searchedColumn,
    searchText,
    selectedKeys,
    handleResetSearchOnColumn,
    handleIsColumnSearched,
    handleSearchOnColumn,
    handleOnSearchColumnChange
  } = useTableFilters();
  const {
    columnNameIsNotEmpty,
    getProjectsByWorkSite,
    getActiveProjects,
    getCustomerProjects,
    getHighNonConformingProjects,
    getSupplierProjects,
    getZeroDefectProjects,
    filterEmptyRowsOnColumn
  } = useProject();

  useEffect(() => {
    let isSubscribed = true;

    if (savedColumns && isSubscribed) {
      save({ key: EntityKey.ProjectTableColumns, value: JSON.parse(savedColumns.configurationSettings).columns });
    }

    return () => {
      isSubscribed = false;
    };
  }, [savedColumns]);

  useActivePage(pages.projects);

  useEffect(() => {
    let isSubscribed = true;

    if (savedFallout && isSubscribed) {
      setFallout(JSON.parse(savedFallout.configurationSettings).percentage);
    }

    return () => {
      isSubscribed = false;
    };
  }, [savedFallout]);

  useEffect(() => {
    if (props && props.removeAnimationClass) {
      props.removeAnimationClass();
    }

    if (project && project.refetch) {
      project.refetch();
    }
  }, []);

  useEffect(() => {
    save({ key: EntityKey.ProjectPreviousData, value: { data: deepCopy(project.data) } });
    let tempUpdatedProjects = [];
    if (project && project.data && projectPreviousData && projectPreviousData.data) {
      tempUpdatedProjects = project.data.reduce((pV, project) => {
        const prevProj = projectPreviousData.data.find(
          x =>
            x.projectId === project.projectId &&
            (x.inspected !== project.inspected ||
              x.projectStatus !== project.projectStatus ||
              x.closedDate !== project.closedDate)
        );
        if (prevProj) {
          const keys = Object.keys(project).filter(key => project[key] !== prevProj[key]);
          return { ...pV, [project.projectId]: keys };
        }
        return pV;
      }, {});
    }
    setUpdatedProjects(tempUpdatedProjects);
  }, [project.data]);

  const setActiveProject = projectId => {
    save({ key: EntityKey.ActiveProject, value: { id: projectId } });
  };

  const goToProjectDetails = projectId => {
    setActiveProject(projectId);
    navigate('/project/details');
  };

  const toggleHiddenEmptyRowsOnColumn = dataIndex => {
    const updatedFilters = deepCopy(filters);
    let hiddenColumns = updatedFilters.hiddenEmptyRowsOnColumn.value;
    if (dataIndex in hiddenColumns) {
      hiddenColumns = { ...hiddenColumns, [dataIndex]: !hiddenColumns[dataIndex] };
    } else {
      hiddenColumns = { ...hiddenColumns, [dataIndex]: true };
    }
    updatedFilters.hiddenEmptyRowsOnColumn.value = hiddenColumns;
    updatedFilters.hiddenEmptyRowsOnColumn.showTag = !updatedFilters.hiddenEmptyRowsOnColumn.showTag;
    setFilters(updatedFilters);
  };

  const allColumns = getAllColumns(null, null, () => <></>, [], fallout);

  const getColumnSearchProps = dataIndex => {
    switch (dataIndex) {
      case 'businessUnitName':
        return {
          filterDropdown: () => (
            <div>
              <Checkbox.Group
                className='custom-filter-checkbox'
                options={(businessUnitsAvailable ?? []).map(businessUnitKey => ({
                  label: businessUnitKey,
                  value: businessUnitKey
                }))}
                value={selectedKeys[dataIndex]}
                onChange={e => handleOnSearchColumnChange(e, dataIndex)}
              />
              <div className='custom-filter-buttons'>
                <Button
                  disabled={!handleIsColumnSearched(dataIndex)}
                  type='link'
                  size='small'
                  onClick={() => handleResetSearchOnColumn(dataIndex)}
                >
                  Reset
                </Button>
                <Button type='primary' size='small' onClick={() => handleSearchOnColumn(dataIndex)}>
                  OK
                </Button>
              </div>
            </div>
          ),
          filterIcon: <FilterFilled style={{ color: handleIsColumnSearched(dataIndex) ? '#1890ff' : undefined }} />
        };
      case 'projectStatus':
        return {
          filterDropdown: () => (
            <div>
              <Checkbox.Group
                className='custom-filter-checkbox'
                options={statusAvailable.map(statusKey => ({
                  label: statusType[statusKey] ?? statusKey,
                  value: statusKey
                }))}
                value={selectedKeys[dataIndex]}
                onChange={e => handleOnSearchColumnChange(e, dataIndex)}
              />
              <div className='custom-filter-buttons'>
                <Button
                  disabled={!handleIsColumnSearched(dataIndex)}
                  type='link'
                  size='small'
                  onClick={() => handleResetSearchOnColumn(dataIndex)}
                >
                  Reset
                </Button>
                <Button type='primary' size='small' onClick={() => handleSearchOnColumn(dataIndex)}>
                  OK
                </Button>
              </div>
            </div>
          ),
          filterIcon: <FilterFilled style={{ color: handleIsColumnSearched(dataIndex) ? '#1890ff' : undefined }} />
        };
      case 'inspected':
        return {
          filterDropdown: () => (
            <div>
              <Checkbox
                checked={filters.hiddenEmptyRowsOnColumn.value['inspected'] || false}
                onChange={() => toggleHiddenEmptyRowsOnColumn('inspected')}
                style={{ padding: 8 }}
              >
                Hide projects with no inspections
              </Checkbox>
              <div className='custom-filter-buttons'>
                <Button
                  disabled={!filters.hiddenEmptyRowsOnColumn.value['inspected'] || false}
                  type='link'
                  size='small'
                  onClick={() => toggleHiddenEmptyRowsOnColumn('inspected')}
                >
                  Reset
                </Button>
              </div>
            </div>
          )
        };
      default:
        return {
          filterDropdown: () => (
            <div style={{ padding: 8 }}>
              <Input
                placeholder={`Search ${allColumns.find(column => column.dataIndex === dataIndex).title}`}
                value={selectedKeys[dataIndex]}
                onChange={e => handleOnSearchColumnChange(e.target.value, dataIndex)}
                onPressEnter={() => handleSearchOnColumn(dataIndex)}
                style={{ width: 188, marginBottom: 8, display: 'block' }}
              />
              <div>
                <Button
                  type='primary'
                  onClick={() => handleSearchOnColumn(dataIndex)}
                  icon={<SearchOutlined />}
                  size='small'
                  style={{ width: 90, marginRight: 10 }}
                >
                  Search
                </Button>
                <Button onClick={() => handleResetSearchOnColumn(dataIndex)} size='small' style={{ width: 90 }}>
                  Reset
                </Button>
              </div>
            </div>
          ),
          filterIcon: <SearchOutlined style={{ color: handleIsColumnSearched(dataIndex) ? '#1890ff' : undefined }} />
        };
    }
  };

  const columns = getProjectTableColumns(
    projectTableColumns,
    setActiveProject,
    goToProjectDetails,
    getColumnSearchProps,
    statusAvailable,
    businessUnitsAvailable,
    fallout
  );

  /* #endregion */

  const filteredProjects = () => {
    let filteredList = getValue(project, 'data', []);
    let percentage = 0;

    if (hasValue(filteredList, 'data')) {
      filteredList = filteredList.data;
    }

    // Filter rows with empty data on certain columns
    if (filters.hiddenEmptyRowsOnColumn && filters.hiddenEmptyRowsOnColumn.value) {
      Object.keys(filters.hiddenEmptyRowsOnColumn.value).forEach(column => {
        if (filters.hiddenEmptyRowsOnColumn.value[column]) {
          filteredList = filterEmptyRowsOnColumn(filteredList, column);
        }
      });
    }

    // check metric param to further filter projects
    if (
      filters.projectMetric &&
      filters.projectMetric.value &&
      filters.projectMetric.value !== projectMetricType.ALL_PROJECTS
    ) {
      switch (filters.projectMetric.value) {
        case projectMetricType.MY_PROJECTS:
          filteredList = getCustomerProjects(
            getValue(filters, 'selectedWorkSite.value', 0),
            getValue(workSite, 'data', []),
            filteredList
          );
          break;
        case projectMetricType.SUPPLIER_PROJECTS:
          filteredList = getSupplierProjects(
            getValue(filters, 'selectedWorkSite.value', 0),
            getValue(workSite, 'data', []),
            filteredList
          );
          break;
        case projectMetricType.ZERO_DEFECTS:
          filteredList = getZeroDefectProjects(
            getValue(filters, 'selectedWorkSite.value', 0),
            workSite && workSite.data ? workSite.data : [],
            filteredList,
            businessContact
          );
          break;
        case projectMetricType.ACTIVE_PROJECTS:
          filteredList = getActiveProjects(
            getValue(filters, 'selectedWorkSite.value', 0),
            getValue(workSite, 'data', []),
            filteredList,
            businessContact
          );
          break;
        case projectMetricType.HIGH_NONCONFORMING:
          if (hasValue(userConfiguration, 'data')) {
            const falloutConfiguration = userConfiguration.data.find(x => x.name === 'fallout');

            if (falloutConfiguration) {
              const configurationSettings = JSON.parse(falloutConfiguration.configurationSettings);
              percentage = configurationSettings.percentage;
            }
          }

          filteredList = getHighNonConformingProjects(
            getValue(filters, 'selectedWorkSite.value', 0),
            getValue(workSite, 'data', []),
            filteredList,
            businessContact,
            percentage
          );

          break;
        default:
          // no metric filter applied
          break;
      }
    }

    // Filter by work sites if there's an active work site.
    if (filters.selectedWorkSite && filters.selectedWorkSite.value) {
      filteredList = getProjectsByWorkSite(filters.selectedWorkSite.value, filteredList);
    }

    // Apply field search filter.
    searchedColumn.forEach(dataIndex => {
      switch (dataIndex) {
        case 'businessUnitName':
          filteredList = filteredList.filter(project => searchText[dataIndex].includes(project.businessUnitName));
          break;
        case 'projectStatus':
          filteredList = filteredList.filter(project => searchText[dataIndex].includes(project.projectStatus));
          break;
        default:
          filteredList = filteredList.filter(
            list =>
              list[dataIndex] &&
              list[dataIndex]
                .toString()
                .toLowerCase()
                .includes(searchText[dataIndex].toLowerCase())
          );
      }
    });

    filteredList.forEach((project, idx, thisArr) => {
      thisArr[idx] = { ...project, key: project.id };
    });

    return filteredList;
  };

  const showFilters = () => {
    setFiltersVisible(true);
  };

  const handleFiltersClose = () => {
    setFiltersVisible(false);
  };

  const formattedProjects = () => {
    const newFormattedProjects = filteredProjects().map(item => {
      return {
        projectId: item.projectId,
        customerReference: item.customerReference,
        projectStatus: item.projectStatus,
        authorization: item.authorization,
        businessUnitName: item.businessUnitName,
        closedDate: item.closedDate !== null ? formatDate(item.closedDate) : item.closedDate,
        workSiteCompanyId: item.workSiteCompanyId,
        customerCompanyId: item.customerCompanyId,
        workSiteCity: item.workSiteCity,
        partNumber: item.partNumber,
        partDescription: item.partDescription,
        criteriaName: item.criteriaName,
        criteriaType: determineCriteriaType(item.criteriaType),
        inspected: item.inspected,
        defects: item.defects,
        reworked: item.reworked,
        good: item.good,
        fallout: item.fallout,
        lastSortDate: item.lastSortDate != null ? formatDate(item.lastSortDate) : item.lastSortDate,
        lastSortDateInspectedTotal: item.lastSortDateInspectedTotal,
        lastSortDateNonConformTotal: item.lastSortDateNonConformTotal,
        lastSortDateReworkTotal: item.lastSortDateReworkTotal,
        lastSortDateGoodTotal: item.lastSortDateGoodTotal,
        lastDefectDate: item.lastDefectDate !== null ? formatDate(item.lastDefectDate) : item.lastDefectDate,
        startDate: item.startDate !== null ? formatDate(item.startDate) : item.startDate,
        workSiteCompanyName: item.workSiteCompanyName,
        customerSupplierName: item.customerSupplierName,
        serviceTypeName: item.serviceTypeName,
        cleanPointDetail: item.cleanPointDetail,

        businessContactId: item.businessContactId,
        dataReportingDescription: item.dataReportingDescription,
        estimatedTotalHours: item.estimatedTotalHours,
        officeName: item.officeName
      };
    });

    return newFormattedProjects;
  };

  const getFormattedColumns = () => {
    const formattedColumns = [];

    columns
      .filter(column => column.dataIndex !== 'id')
      .forEach(column => {
        if (column.children) {
          column.children.forEach(nestedColumn => {
            const formattedNestedColumn = {
              ...nestedColumn,
              title: `${column.title} - ${nestedColumn.title}`
            };

            formattedColumns.push(formattedNestedColumn);
          });
        } else {
          formattedColumns.push(column);
        }
      });

    return formattedColumns;
  };

  const fullscreenClass = props.isFullscreen ? 'hide' : 'hide-on-mobile';

  const showFullscreen = () => {
    navigate('/projects/fullscreen');
  };

  const handleDownload = () => {
    const endpointRequiredData = {
      projectId: null,
      runByName: businessContact.data.displayName,
      reportName: `Projects Report`,
      runTime: moment().format('YYYY-MM-DD LT')
    };

    exportToExcel(
      `Projects-${formatDate(new Date())}`,
      getFormattedColumns(),
      formattedProjects(),
      endpointRequiredData
    );
  };

  const handleReload = () => {
    if (project.refetch) {
      project.refetch();
    }
  };

  const menu = (
    <Menu>
      {/* <Menu.Item
        key={uuid()}
        onClick={() => exportToCsv(`Projects-${formatDate(new Date())}`, getFormattedColumns(), formattedProjects())}
      >
        Export to CSV
      </Menu.Item> */}
      <Menu.Item key={uuid()} onClick={handleDownload}>
        Export to Excel
      </Menu.Item>
      <Menu.Item key={uuid()} onClick={handleReload}>
        Reload
      </Menu.Item>
      <Menu.Item key={uuid()} className={fullscreenClass} onClick={() => showFullscreen()}>
        Fullscreen
      </Menu.Item>
      <Menu.Item key={uuid()} onClick={() => showFilters()}>
        Settings
      </Menu.Item>
    </Menu>
  );

  const extra = (
    <Dropdown className='export-to-csv' overlay={menu} trigger={['click']} placement='bottomRight'>
      <button className='link-button' type='button'>
        <MaterialIcon role='button' icon='more_vert' />
      </button>
    </Dropdown>
  );

  const table = () => {
    if (
      !project ||
      (project && project.isLoading && !project.isRefetching) ||
      (project && typeof project.isLoading === 'undefined')
    ) {
      return (
        <Card style={{ marginTop: 25 }}>
          <Skeleton active />
        </Card>
      );
    }

    // NOTE: The search option can have a 'initValue', which can be used
    // to persist the search in the next render
    const searchOptions = [
      {
        key: 'projectId',
        placeholder: 'Project #'
      },
      {
        key: 'customerReference',
        placeholder: 'Ref #'
      },
      {
        key: 'partNumber',
        placeholder: 'Part #'
      }
    ];

    const filterProjects = () => {
      searchOptions.forEach(option => {
        if (selectedKeys[option.key] !== undefined) {
          handleSearchOnColumn(option.key);
        }
      });
    };

    const mobileProjectFilter = (
      <div className='show-on-mobile'>
        <Row gutter={[16, 8]} style={{ padding: 15 }}>
          {searchOptions.map(({ placeholder, key }) => (
            <Col xs={24} sm={6} key={`input-search-${key}`}>
              <Input
                placeholder={placeholder}
                key={`search-input-${key}`}
                onPressEnter={() => filterProjects()}
                value={selectedKeys[key]}
                onChange={e => handleOnSearchColumnChange(e.target.value, key)}
              />
            </Col>
          ))}
          <Col xs={24} sm={6}>
            <Button className='ant-btn-primary' onClick={() => filterProjects()}>
              Filter
            </Button>
          </Col>
        </Row>
      </div>
    );

    return (
      <>
        <div>
          <Disclaimer />
        </div>
        <Card id='project_table' className='table-card' title={strings.projects} extra={extra}>
          <div className='table-tags-container'>
            <FilterIndicators filterProperties={filterProperties} closableFilters onCloseFilter={resetSingleFilter} />
            <TableFilterIndicators
              searchedColumn={searchedColumn}
              searchText={searchText}
              handleReset={handleResetSearchOnColumn}
              allColumns={allColumns}
            />
          </div>
          {mobileProjectFilter}
          <Table
            size='middle'
            className='project-table hide-on-mobile'
            rowClassName={projectRow =>
              `${projectRow.projectStatus === 'A' ? 'table-active-project' : 'table-closed-project'} ${
                projectRow.projectId in updatedProjects
                  ? updatedProjects[projectRow.projectId].map(key => `table-project-column-updated-${key}`).join(' ')
                  : ''
              }`
            }
            scroll={{ x: true }}
            columns={columns}
            dataSource={filteredProjects()}
            pagination={{
              pageSize,
              showSizeChanger: true,
              pageSizeOptions: ['10', '20', '50', '100'],
              onShowSizeChange: (_, size) => {
                setPageSize(size);
              }
            }}
          />
        </Card>
        <div className='mobile-cards'>
          <ProjectMobileCards projects={filteredProjects()} />
        </div>
      </>
    );
  };

  return (
    <>
      {table()}
      <Drawer visible={filtersVisible} onClose={handleFiltersClose} placement='right'>
        <FiltersDrawer
          allColumns={allColumns}
          filters={filters}
          setFilters={setFilters}
          toggleHiddenEmptyRowsOnColumn={toggleHiddenEmptyRowsOnColumn}
        />
      </Drawer>
    </>
  );
});
